import React from 'react'

import { ReactComponent as Ers } from '../svg/ers.svg'

export default function Footer() {
  return (
    <footer className="footer">
      {/* <Ers />
      <p>Verified by ERS</p> */}
    </footer>
  )
}
